import '@usb-shield/react-link/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import React from 'react'
import { NonSupportedAreaProps } from './nonSupportedArea.model'
import nonSupportedStyles from '@/components/NonSupportedArea/nonSupportedArea.module.scss'
import { setExpirationDays } from '@/utils/cookies/cookies'
import { setCookie } from 'cookies-next'
import router from 'next/router'
import USBLink from '@usb-shield/react-link'

const outerLayoutOption = {
  span: null,
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'auto',
  justify: 'auto',
}

const innerLayoutOption = {
  span: null,
  spans: {
    xlarge: 16,
    large: 16,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'auto',
  justify: 'auto',
}

export const NonSupportedArea = ({
  countryWrapperData,
}: NonSupportedAreaProps) => {
  const onCountryCardClick = (countryLink: string) => {
    const countryLocale = countryLink.split('/')[3]

    setCookie('locale', countryLocale, {
      expires: setExpirationDays(90),
    })

    setCookie('countryCode', countryLocale.replace('en-', '').toUpperCase(), {
      expires: setExpirationDays(90),
    })

    router.push('/').then(() => {
      window.location.reload()
    })
  }

  return (
    <USBGrid
      gridGap="normal"
      alignItems="middle"
      columnCount="16"
      justifyContent="stretch"
      display="grid"
      padding="normal"
      addClasses={nonSupportedStyles.nonSupportedAreaWrapper}
    >
      <USBColumn
        layoutOpts={outerLayoutOption}
        addClasses={nonSupportedStyles.heading}
      >
        {countryWrapperData.heading}
      </USBColumn>

      <USBColumn
        layoutOpts={outerLayoutOption}
        addClasses={nonSupportedStyles.nonSupportedAreaContent}
      >
        <USBGrid
          backgroundColor="heroBg"
          gridGap="normal"
          alignItems="middle"
          columnCount="16"
          justifyContent="stretch"
          display="grid"
          padding="normal"
        >
          <USBColumn
            layoutOpts={innerLayoutOption}
            addClasses={nonSupportedStyles.headingWrapper}
          >
            <p className={nonSupportedStyles.subheading}>
              {countryWrapperData.subheading}
            </p>
            <p className={nonSupportedStyles.description}>
              {countryWrapperData.description}
            </p>
          </USBColumn>

          {countryWrapperData.regionDetails.map((regionDetail) => (
            <USBColumn
              layoutOpts={innerLayoutOption}
              addClasses={nonSupportedStyles.countryCardListWrapper}
            >
              <p className={nonSupportedStyles.regionName}>
                {regionDetail.regionName}
              </p>
              <div className={nonSupportedStyles.countryCardList}>
                {regionDetail.countryDetails.map((countryDetail) => (
                  <div
                    className={nonSupportedStyles.countryCard}
                    onClick={() =>
                      onCountryCardClick(countryDetail.countrylinks)
                    }
                  >
                    <img
                      width={24}
                      height={16}
                      src={`${process.env.CONTENT_DOMAIN}${countryDetail.countryFlag}`}
                      alt="SVG as an image"
                    />
                    <div className={nonSupportedStyles.countryCardText}>
                      {countryDetail.countryName}
                    </div>
                  </div>
                ))}
              </div>
            </USBColumn>
          ))}

          <USBColumn
            layoutOpts={innerLayoutOption}
            addClasses={nonSupportedStyles.contactUsSection}
          >
            <div className={nonSupportedStyles.contactUsCard}>
              <div className={nonSupportedStyles.contactUsText}>
                Need help? Talk to our support team.
              </div>

              <USBLink
                linkType="arrow"
                href={''}
                dataTestId={`non-supported-area-contact-us`}
                addClasses={nonSupportedStyles.contactUsLink}
              >
                Contact us
              </USBLink>
            </div>
          </USBColumn>
        </USBGrid>
      </USBColumn>
    </USBGrid>
  )
}
