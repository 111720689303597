import '@usb-shield/react-link/dist/library/styles/index.css'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import { defaultColLayoutOptions } from '@/utils/usb-grid.util'
import { FeatureItemData } from '@/components/FeaturesArrayWrapper/featuresArrayWrapper.model'
import styles from '@/components/FeaturesArrayWrapper/featuresArrayWrapper.module.scss'
import React from 'react'
import FeatureCard from './displayViews/FeatureCard'

const fullWidthColumn = {
  ...defaultColLayoutOptions,
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0,
  },
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4,
  },
}

const twelveColsOption = {
  span: null,
  spans: {
    xlarge: 12,
    large: 12,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 2,
    large: 2,
    medium: 0,
    small: 0,
  },
  display: 'flex',
  padding: 'zero',
  align: 'start',
  justify: 'start'
}

let textColumn = {
  ...twelveColsOption,
  offset: {
    xlarge: 2,
    large: 0,
    medium: 0,
    small: 0
  },
  spans: {
    xlarge: 12,
    large: 16,
    medium: 8,
    small: 4
  }
}

let defaultDisplayTagIndex: number;

const FeaturesArrayWrapper = ({
  title,
  featureCategories,
  apiFeatureCFM
}: FeatureItemData) => {
  const featureCards = featureCategories.map((res, index) => {
    let cards = apiFeatureCFM.filter((card: any) => card.featureTags.includes(res.id))
    return { cards }
  })

  const tiles = featureCards.map(option => {
    return option.cards
  })
  
  return (
    <USBGrid
      gridGap='normal'
      alignItems='start'
      columnCount='16'
      justifyContent='stretch'
      display='grid'
      padding='normal'
      addClasses={styles.gridWrapper}
    >
      <USBColumn layoutOpts={fullWidthColumn} addClasses={styles.fullWidth}>
        {title && (
          <h2 className='headH2' data-testid='title'>
            {title}
          </h2>
        )}
      </USBColumn>

      <USBColumn layoutOpts={textColumn} addClasses={styles.fullWidth}>
        <USBGrid
          gridGap='normal'
          alignItems='start'
          columnCount='12'
          justifyContent='stretch'
          display='grid'
          padding='zero'
          addClasses={styles.TwelveColSubGridFix}
        >
          <FeatureCard
            tiles={tiles[0]}
          />
        </USBGrid>
      </USBColumn>
    </USBGrid>
  )
}

export default FeaturesArrayWrapper
