import USBLink from '@usb-shield/react-link'
import blockStyles from '@/components/BlockArrayWrapper/blockArrayWrapper.module.scss'
import styles from '@/components/BrowseDocs/browseDocs.module.scss'
import { USBColumn, USBGrid } from '@usb-shield/react-grid'
import { colWidth } from '../BlockArrayWrapper/BlockArrayWrapper'
import { defaultGridOptions } from '@/utils/usb-grid.util'

export const BrowseDocs = ({ browseDocs }: { browseDocs: any }) => {
  return (
    <USBGrid
      gridGap={'zero'}
      alignItems={defaultGridOptions.alignItems}
      columnCount={defaultGridOptions.columnCount}
      justifyContent={defaultGridOptions.justifyContent}
      display={defaultGridOptions.display}
      padding={defaultGridOptions.padding}
      addClasses={blockStyles.gridWrapper}
    >
      <USBColumn layoutOpts={colWidth} addClasses={blockStyles.fullWidth}>
        <div className={styles.browseDocs}>
          <h1 className={styles.browserDocsTitle}>{browseDocs.heading}</h1>
          <p
            className={styles.browseDocsDescription}
            dangerouslySetInnerHTML={{
              __html: browseDocs.description,
            }}
          ></p>
          <USBLink
            linkType="arrow"
            href={browseDocs.ctaLink}
            addClasses={styles.browseDocsCtaLink}
          >
            {browseDocs.ctaText}
          </USBLink>
        </div>
      </USBColumn>
    </USBGrid>
  )
}
